.login-view {
  background-color: #cad7eb;
  background-image: linear-gradient(
    107deg,
    #a0c1f5 0%,
    #cbd7ec 50%,
    #99bdf6 100%
  );
  min-height: calc(100vh - 6rem);

  a {
    color: #136ef1;
    text-decoration: none;
  }

  h1 {
    color: #000;
    font-size: 3.125rem;
  }

  h2 {
    color: #000;
    font-size: 1.125rem;
    font-weight: normal;
  }

  input {
    background-color: #fff !important;
    border: none !important;
    border-radius: 0.4rem !important;
    color: #000;
    font-size: 1.625rem;
    margin: 0 !important;
  }

  button,
  button:hover {
    background-color: #459754;
    border-radius: 0.4rem !important;
    color: #fff;
    font-size: 1.625rem;
    font-weight: normal;
    padding: 1.22rem;
  }

  p {
    color: #000;
    font-size: 1.125rem;
  }

  .invalid-feedback {
    color: #ff2a00;
    font-weight: bold;
  }

  button.google,
  button.google:hover,
  button.google:active {
    background-color: #459754;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='705.6' height='720' viewBox='0 0 186.69 190.5' xmlns:v='https://vecta.io/nano'%3E%3Cg transform='translate(1184.583 765.171)'%3E%3Cpath clip-path='none' mask='none' d='M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z' fill='%23ffffff'/%3E%3Cpath clip-path='none' mask='none' d='M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z' fill='%23ffffff'/%3E%3Cpath clip-path='none' mask='none' d='M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z' fill='%23ffffff'/%3E%3Cpath d='M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z' fill='%23ffffff' clip-path='none' mask='none'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 2rem;
    background-position: 1.5rem 1rem;
    margin: 1rem;
    width: calc(100% - 2rem);
  }
}
