#text-area-view {
  border: 1px solid grey;
}

#required-text {
  color: red;
}

#number-input {
  display: inline-flex;
}

#dollar-sign {
  font-size: 1.5rem;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 0.5rem;
}
