#coming-soon-product-card-top-row {
  display: flex;
  flex-direction: row;
}

#coming-soon-flag {
  margin-left: auto;
  background-color: #93e1ed;
  border-radius: 0px 0px 5px 5px;
  min-width: fit-content;
  padding: 0.5rem;
  margin-top: 0;
}

#coming-soon-product-card-body {
  padding-top: 0rem;
}

#coming-soon-product-card-name {
  padding-top: 0.5rem;
}

#coming-soon-product-card-action-button {
  width: fit-content;
  height: fit-content;
  align-self: flex-end;
  background-color: #fd8067;
  margin-right: 1rem;
  margin-bottom: 00.5rem;
}

#coming-soon-product-card-action-button:disabled {
  opacity: 1;
  width: fit-content;
  height: fit-content;
  align-self: flex-end;
  background-color: #828282;
  margin-right: 1rem;
  margin-bottom: 00.5rem;
  pointer-events: none;
}

#coming-soon-product-card-action-span {
  width: fit-content;
  height: fit-content;
  align-self: flex-end;
}
