.square:before {
  content: "";
  left: 70px;
  top: -10px;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #fd8067 transparent;
  z-index: 9999;
}
.square:after {
  content: "";
  left: 64px;
  top: -18px;
  width: 0;
  position: absolute;
  height: 0;
  border-style: solid;
  border-width: 0 17px 17px 17px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 9998;
}
.square {
  background: #fae0bb;
  border: 8px solid #fffefe;
  float: left;
  margin-right: 70px;
  top: 178px;
  right: 0;
  width: 200px;
  z-index: 99999;
  position: absolute;
}

.content {
  display: flex;
  font-size: 0.8rem;
  color: #fff;
  background-color: #fd8067;
  padding: 5px;
}
.react-toast-notifications__container {
  position: unset !important;
}

.icon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
.square.show {
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
