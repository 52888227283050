.payment-complete-view {
  p {
    font-size: 3rem;
    padding: 12rem 0 16rem 0;
    text-align: center;
  }

  button {
    border-radius: 10rem;
    font-size: 1.5rem;
    margin-top: 2rem;
    padding: 1rem 2rem;
  }
}
