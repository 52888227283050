#privacy-policy-view {
  color: #fff;
  margin: 2rem;
  padding-bottom: 10rem;
}

#privacy-policy-view h1 {
  color: #fff;
}

#privacy-policy-view h1:first-child {
  text-align: center;
}

#privacy-policy-view h2 {
  color: #fff;
}

#privacy-policy-view ul li:not(:last-child) {
  margin-bottom: 1rem;
}

#privacy-policy-view a {
  color: #fff;
}

#privacy-policy-view table {
  background-color: #fff;
  border-collapse: initial;
}

#privacy-policy-view table th {
  color: #175fd3;
  white-space: nowrap;
}

#privacy-policy-view table th,
#privacy-policy-view table td {
  padding: 1rem;
}

#privacy-policy-view table td {
  background-color: #175fd3;
}
