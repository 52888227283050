@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "bootswatch";

html,
body {
  height: auto;
}

*:not(i):not(.fa) {
  font-family: "Roboto";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: none;
  letter-spacing: 1px;
}

.banner {
  margin: 2px 12px;
}

.btn {
  text-transform: none;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

/* Self-adjusting font size */

html {
  font-size: 10px;
}

@media screen and (min-width: 1025px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 1601px) {
  html {
    font-size: 15px;
  }
}

@media screen and (min-width: 1801px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 2561px) {
  html {
    font-size: 18px;
  }
}
