.register-view {
  background-color: #cad7eb;
  background-image: linear-gradient(
    107deg,
    #a0c1f5 0%,
    #cbd7ec 50%,
    #99bdf6 100%
  );
  color: #000;
  min-height: calc(100vh - 6rem);

  h1 {
    color: inherit;
    font-style: normal;
    font-weight: 700;
    font-size: 2.75rem;
    line-height: 3rem;
    margin-bottom: 3rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  ul.list {
    list-style: none;
    padding: 0;

    li {
      background: transparent
        url("data:image/svg+xml,%3Csvg width='26' height='18' viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.3441 1.82125L7.96528 17.3212L0 10.2171L2.04201 8.39583L7.96528 13.6658L23.3021 0L25.3441 1.82125Z' fill='%23219653'/%3E%3C/svg%3E%0A")
        left 0.5rem no-repeat;
      margin-bottom: 1rem;
      margin-left: -2.75rem;
      padding-left: 2.75rem;
      font-style: normal;
      font-size: 1.4rem;
      line-height: 2.25rem;
    }
  }

  .white {
    background-color: #fff;
    border-radius: 1.5rem;

    h1 {
      color: #2e7cf6;
    }

    input,
    select {
      background-color: #fff !important;
      border-radius: 0.75rem !important;
      color: #000;
      font-size: 1.625rem;
      margin: 0 0 1.5rem 0 !important;
    }

    button,
    button:hover {
      background-color: #459754;
      border-radius: 0.75rem !important;
      color: #fff;
      font-size: 1.625rem;
      font-weight: normal;
      padding: 1.22rem;
    }

    span {
      display: block;
      font-size: 1.325rem;
      margin: 0 !important;
      padding: 3.5rem 0 0 0 !important;
    }
  }
}
