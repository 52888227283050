.step-group {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.step-header-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #0c0c0c;
  margin-bottom: 0px;
}

.completed-step-header-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #219653;
  margin-bottom: 0px;
}

#disable-access-text {
  font-size: 14px;
  font-style: italic;
}

/* .access-button-option {
  background: rgba(190, 190, 190, 0.18);
  border-radius: 4px;
  margin: 10px;
  padding: 10px;
  border: 1px solid lightgray;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;

  color: #6b6b6b;
} */

/* .access-button-option:disabled,
.access-button-option[disabled] {
  background: lightgrey;
  border-radius: 4px;
  margin: 10px;
  padding: 10px;
  border: 1px solid lightgray;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  text-align: center;

  color: #6b6b6b;
} */

#limited-option-message {
  font-size: 14px;
}

#review-terms-box {
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid #219653;
  box-sizing: border-box;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #6b6b6b;
}

#terms-accept-button {
  background: #000000;
  border-radius: 4px;
  color: white;
  padding: 4px;
  padding-right: 8px;
  padding-left: 8px;
}
