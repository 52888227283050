.header {
  background-color: #fff;
  padding: 1.5rem 3.2rem 1.5rem 4.5rem;

  & > .row {
    align-items: center;
  }

  .navbar-brand {
    padding: 0;
    margin: 0;

    img {
      width: 14rem;
    }
  }

  a {
    display: inline-block;
  }

  ul {
    padding: 0;
    margin: 0;

    a {
      color: #000;
      font-style: normal;
      font-weight: 400;
      font-size: 1.85rem;
      padding: 1rem;
      text-decoration: none;
      text-transform: none;

      &:not(:first-child) {
        margin-left: 2rem;
      }

      &:hover {
        color: #2e7cf6;
      }
    }
  }

  .dropdown {
    margin-right: -2rem;
    position: initial;

    .dropdown-toggle {
      font-size: 2rem;
      padding: 0 1rem;

      &:after {
        display: none;
      }
    }

    .dropdown-menu {
      background-color: #fff;
      top: 6.15rem;
      left: 0;
      right: 0;
      padding: 0;
      border: 0;

      a {
        display: block;
        font-size: 2.5rem;
        font-weight: normal;
        text-align: right;
        line-height: 5rem;
        padding: 0 1rem;
        text-decoration: none;
      }
    }
  }
}
