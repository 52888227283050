.bg-blue-1 {
  background-color: rgb(229, 239, 255);
}

.pt-9 {
  padding-top: 120px;
}
.justify-between {
  justify-content: space-between;
}
.items-stretch {
  align-items: stretch;
}
.max-w-screen-1920 {
  max-width: 1920px;
}
.flex {
  display: flex;
}
.relative {
  position: relative;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 80px;
  padding-right: 80px;
}
.font-gilroy {
  font-size: 72px;
}
.my-6 {
  font-size: 36px;
}

.z-10 {
  z-index: 10;
}

.w-12 {
  width: 600px;
}

.align-middle {
  vertical-align: middle !important;
}
.dotGrapic {
  top: 580px;
  margin: 0px !important;
}
.max-w-screen-1920 {
  max-width: 1920px;
}
.absolute {
  position: absolute;
}

.z-0 {
  z-index: 0;
}
