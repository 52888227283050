#waitlist-product-card-buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
}

#waitlist-product-card-waitlisted-button {
  border-radius: 3px;
  margin: 10px 10px;
  color: #f2f2f2;
  flex-grow: 1;
}

#waitlist-product-card-status-button {
  cursor: pointer;
  border-radius: 3px;
  margin: 10px 10px;
  flex-grow: 1;
}

#waitlist-product-card-name {
  padding-top: 0.5rem;
}

#waitlist-product-card-top-row {
  display: flex;
  flex-direction: row;
}
#waitlist-product-card-body {
  padding-top: 0rem;
}

#waitlist-product-flag {
  margin-left: auto;
  background-color: #4f7ddd;
  border-radius: 0px 0px 5px 5px;
  min-width: fit-content;
  padding: 0.5rem;
  margin-top: 0;
  color: white;
}

#waitlist-product-card-credits {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #4f7ddd;
  margin-left: auto;
}

#waitlist-product-card-bottom-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

/* Waitlist Status Reveal Styles */

#waitlist-status-div {
  display: flex;
  flex-direction: column;
  border: 0.5px solid #219653;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px #c5c5c5;
  border-radius: 3px;
  padding: 5px;
}

#waitlist-status-header-text {
  font-size: 14px;
  font-weight: 700;
  color: #000000;
}
#waitlist-next-available-header-text {
  font-size: 10px;
  font-weight: 400;
  color: #4f4f4f;
}

#waitlist-next-available-time-text {
  font-size: 10px;
  font-weight: 500;
  color: #219653;
}

#waitlisted-button {
}
#leave-waitlist-button {
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: flex-end; */
  /* padding: 1px 12px; */
  border-radius: 4px;
  background-color: #f5f5f5;
}

#leave-waitlist-text {
  font-size: 10px;
  font-weight: 400;
  color: white;
}
