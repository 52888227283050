.onboarding-view {
  background-color: #fff;

  a {
    text-decoration: none;
  }

  .header {
    background-color: #1c222b;

    img {
      filter: contrast(100000%) grayscale(100%) brightness(100%) invert(100%);
    }

    ul {
      a {
        color: #fff;

        &:hover {
          color: #ccc;
        }
      }
    }
  }

  .section-1 {
    color: #1c222b;

    .row {
      padding: 4rem 6rem 8rem 6rem;
    }

    p {
      margin: 2rem 0;
    }

    h1 {
      color: #2e7cf6;
      font-size: 3.2rem;
    }

    ul {
      list-style-image: url("data:image/svg+xml,%3Csvg width='26' height='18' viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.3441 1.82125L7.96528 17.3212L0 10.2171L2.04201 8.39583L7.96528 13.6658L23.3021 0L25.3441 1.82125Z' fill='%23219653'/%3E%3C/svg%3E%0A");
      margin: 1.5rem;

      li {
        font-style: normal;
        font-size: 1.5rem;
        line-height: 2.25rem;
        padding-left: 1rem;
        margin-bottom: 1rem;
      }
    }

    button {
      background-color: #f78d83;
      border-radius: 0.5rem;
      font-size: 1.5rem;
      margin: 1rem 0;
      padding: 1.5rem 3rem;
    }

    span.guarantee {
      background: transparent url("../../public/images/green-shield.svg")
        no-repeat center left;
      background-size: contain;
      display: block;
      font-size: 1rem;
      font-weight: 400;
      padding-left: 1.5rem;
      line-height: 1.5rem;
      margin: 2rem 0;
    }

    fieldset {
      border: 2px solid #f78d83;
      border-radius: 1rem;
      display: inline-block;
      margin-top: 1rem;

      legend {
        background-color: #fff;
        color: #488df8;
        font-size: 1rem;
        margin-top: -0.65rem;
        margin-bottom: 0;
        margin-left: 1rem;
        padding: 0 0.25rem;
        position: absolute;
        text-transform: uppercase;
        width: auto;
      }

      a,
      a:hover {
        color: #488df8;
        display: block;
        font-size: 1.25rem;
        font-weight: 500;
        padding: 1rem 3rem;
        text-decoration: underline;
      }
    }
  }

  .section-1-5 {
    .partners {
      background-color: #efefef;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      margin: 0 auto;

      .col {
        flex: 1;
        text-align: center;

        img {
          padding: 2rem;
          width: 80%;
        }
      }
    }
  }

  .section-2 {
    h1 {
      color: #000;
      margin-bottom: 3rem;
    }

    p {
      color: #000;
      font-size: 1.5rem;
      margin-bottom: 4rem;
    }

    a {
      font-weight: bold;
      text-decoration: underline;
    }

    .row > div {
      padding: 8rem 6rem;
    }
  }

  .section-3 {
    h2 {
      color: #000;
      font-size: 2.5rem;
      margin-bottom: 3rem;
    }

    p {
      color: #000;
      font-size: 1.5rem;
      margin-bottom: 4rem;
    }

    a {
      font-weight: bold;
      text-decoration: underline;
    }

    .slider-container {
      height: 100%;
      overflow: hidden;

      .slider-control-centerleft {
        margin-left: 1.5rem;
      }

      .slider-control-centerright {
        margin-right: 1.5rem;
      }

      .slider-control-centerleft,
      .slider-control-centerright {
        button {
          background-color: #d9d9d9 !important;
          border-radius: 100%;
          color: #000 !important;
          width: 2.5rem;
          height: 2.5rem;
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1rem;

          &:disabled {
            display: none;
          }
        }
      }

      .paging-item {
        button {
          padding: 10px;
        }
      }
    }
  }

  .footer {
    background-color: #1c222c;
    color: #cfcfcf;
    padding: 4.5rem;

    ul {
      color: #cfcfcf;
      display: flex;
      padding: 0;
      list-style: none;

      a {
        color: #a9a9a9;
      }

      li {
        flex: 1;
        margin-right: 3rem;
        white-space: nowrap;
        font-weight: 700;
        font-size: 1.525rem;
        line-height: 3.325rem;

        ul {
          display: block;
          margin-top: 1rem;

          li {
            font-size: 1.525rem;
            line-height: 3.325rem;
            font-weight: 400;
          }
        }
      }
    }

    h2 {
      color: #fff;
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 1.75rem;
    }

    button {
      background-color: #dedede;
      border: 1px solid #dedede;
      border-radius: 0.5rem;
      color: #2e7cf6;
      font-size: 1.5rem;
      font-weight: 500;
      padding: 1rem 3rem;

      &:hover {
        background-color: #2e7cf6;
        color: #dedede;
      }
    }
  }
}
