/* Styling for the header and expiration */

#access-product-name-expiration-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding: 0px;
  align-self: stretch;
  margin: 0px 0px;
}

#access-product-name {
  font-weight: 700;
}

#access-product-expiration {
  color: #367cff;
  margin-left: auto;
  font-weight: bold;
  font-size: 14px;
}

#access-product-card-name {
  padding-top: 0.5rem;
}

#access-product-card-top-row {
  display: flex;
  flex-direction: row;
}
#access-product-card-bottom-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
#access-product-card-body {
  padding-top: 0rem;
}

#access-product-card-credits {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #2e7cf6;
  margin-left: auto;
}

#access-product-flag {
  margin-left: auto;
  background-color: #fd8067;
  border-radius: 0px 0px 5px 5px;
  min-width: fit-content;
  padding: 0.5rem;
  margin-top: 0;
  color: white;
}

/* Styling for the credentials section */

#access-product-credentials-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#access-product-credentials-header {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  font-style: normal;
  margin: 5px 0px;
  color: #219653;
}

#access-expiration {
  color: #367cff;
  margin-left: auto;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0px;
}

#username-div {
  display: flex;
  flex-direction: row;
}

.access-text-message {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  font-style: normal;
  margin-bottom: 5px;
}

#access-product-un-pw {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 5px;
}

#pw-reveal-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: 5px;
}

#password-revealing-icon {
  cursor: pointer;
  color: #1960d2;
}

#access-product-copy-un-icon {
  padding: 0px 3px;
  margin-left: auto;
  margin-right: 5px;
  align-self: baseline;
  cursor: pointer;
  color: #1960d2;
  font-size: 12px;
}

#access-product-copy-pw-icon {
  padding: 0px 3px;
  margin-left: auto;
  margin-right: 5px;
  cursor: pointer;
  color: #1960d2;
  font-size: 12px;
  align-self: baseline;

  /* margin: 0px 5px; */
}
