#postaccess-survey-div {
  display: flex;

  flex-direction: column;
  justify-content: space-evenly;
}

#postaccess-survey-question-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: fit-content;
}

#rate-your-last {
  color: #367cff;
}
#please-complete {
  color: #fa8072;
  font-weight: 600;
}
