#welcome-message {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 4rem;
  line-height: 100px;
  margin-bottom: 4rem;
  /* identical to box height, or 139% */

  color: #000;
}

.section-header {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 48px;
  /* identical to box height, or 133% */

  margin-bottom: 2rem;
  color: #000;
}

.sub-section-header {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 45px;
  /* identical to box height */

  color: #2e7cf6;
}

#help-view {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  /* or 150% */

  color: #4f4f4f;
}

.img-style {
  width: 40%;
}

.row-section {
  margin-bottom: auto;
}

.row-group {
  background: #feffff;
  /*margin: auto;*/
  padding: 1rem;
  /*box-sizing: border-box;*/
  /*box-shadow: 5px 5px 20px rgb(196 196 196 / 33%);*/
  /*border-radius: 5px;*/
  /*border: 1px solid #0069f5;*/
  /*margin: 0px 25px;*/
}

.col-text {
  margin: auto;
}

.accordion {
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
  align-items: center;
}

#help-view .accordion-item {
  border: none;
}
.accordion-active {
  background: #fd8067;
}
.accordion-nonactive {
  background: #2e7cf6;
}

.accordion-icon {
  border-radius: 50%;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  line-height: 24px;
  padding-left: 2px;
  padding-bottom: 1px;
  max-width: 25px;
  min-width: 25px;
}
.accordion-tile-name {
  font-size: 20px;
  font-weight: normal;
  color: #6b6b6b;
  line-height: 2rem;
}
.accordion-tile-active {
  font-weight: 600;
  color: #000;
}

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }

  .accordion {
    width: 90%;
  }
}
