/* #home-view-filter-options {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  justify-content: flex-start;
} */
#filter-intro {
  color: #828282;
}

#category-pills {
  border-radius: 12px;
  border: 0px;
  margin: 0px 8px;
  opacity: 0.8;
  padding: 4px 12px;
  text-align: center;

  &:hover {
    opacity: 1;
  }

  &.display-only {
    opacity: 1;
    cursor: default;
  }
}
