.signup-input-field {
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  display: inline-block;
  margin: 0rem 0;
  overflow: hidden;
  max-width: 100%;

  button.btn,
  button.btn:hover {
    background-color: #0e66ff;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 1.65rem;
    line-height: 3.5rem;
    white-space: nowrap;
  }
}
