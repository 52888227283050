#shopping-product-card-body {
  padding-top: 0rem;
}

#shopping-product-card-name {
  padding-top: 0.5rem;
}

#shopping-product-flag {
  margin-left: auto;
  background-color: #4f7ddd;
  border-radius: 0px 0px 5px 5px;
  min-width: fit-content;
  padding: 0.5rem;
  margin-top: 0;
  color: white;
}

#shopping-product-card-top-row {
  display: flex;
  flex-direction: row;
}

#shopping-product-card-bottom-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
