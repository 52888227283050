.main-view {
  color: #000;

  a {
    text-decoration: none;
  }

  .separated {
    border-top: 2rem solid #f5f5f5;
    border-bottom: 2rem solid #f5f5f5;
  }

  h2 {
    color: inherit;
    font-style: normal;
    font-weight: 700;
    font-size: 2.75rem;
    line-height: 3rem;
  }

  h3 {
    color: inherit;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .blue {
    color: #2e7cf6;
  }

  .white {
    color: #fff;
  }

  .section-1 {
    background-color: #1c222c;
    color: #fff;
    padding: 8rem 4.5rem;

    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .rating {
      color: #a5b2d3;
      display: inline-block;
      font-weight: 800;
      font-size: 2rem;
      line-height: 3.325rem;
      margin-bottom: 5rem;

      .stars {
        border-right: 1px solid #a5b2d3;
        display: inline-block;
        margin-right: 12px;
        padding-right: 14px;
      }
    }

    h1 {
      color: inherit;
      font-weight: 400;
      font-size: 3.325rem;
      line-height: 3.65rem;
    }
  }

  .section-2 {
    h2 {
      font-size: 3.125rem;
    }

    p {
      font-size: 1.5rem;
    }

    button,
    button:hover {
      background-color: #000;
      border-radius: 1rem;
      color: #fff;
      font-size: 2.165rem;
      font-weight: 500;
      padding: 2rem 5rem;
    }
  }

  .section-2,
  .section-3 {
    .slider-container {
      height: 100%;
      max-width: 100vw;
      overflow: hidden;

      .slider-control-centerleft {
        margin-left: 1.5rem;
      }

      .slider-control-centerright {
        margin-right: 1.5rem;
      }

      .slider-control-centerleft,
      .slider-control-centerright {
        button {
          background-color: #d9d9d9 !important;
          border-radius: 100%;
          color: #000 !important;
          width: 2.5rem;
          height: 2.5rem;
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1rem;

          &:disabled {
            display: none;
          }
        }
      }

      .paging-item {
        button {
          padding: 10px;
        }
      }
    }
  }

  .section-3 {
    a {
      font-size: 1.4rem;
      text-decoration: underline;
    }
  }

  .section-4 {
    button,
    button:hover {
      background-color: #f58d83;
      border-radius: 1rem;
      color: #fff;
      font-size: 2.165rem;
      font-weight: 500;
      padding: 2rem 5rem;

      &.orange {
        background-color: #f58d83;
      }
    }

    ul.list {
      list-style-image: url("data:image/svg+xml,%3Csvg width='26' height='18' viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.3441 1.82125L7.96528 17.3212L0 10.2171L2.04201 8.39583L7.96528 13.6658L23.3021 0L25.3441 1.82125Z' fill='%23219653'/%3E%3C/svg%3E%0A");
      margin-left: -2rem;

      li {
        margin-bottom: 1rem;
        font-style: normal;
        font-size: 1.85rem;
        line-height: 2.25rem;
      }
    }
  }

  .footer {
    background-color: #1c222c;
    color: #cfcfcf;
    padding: 4.5rem;

    ul {
      color: #cfcfcf;
      display: flex;
      padding: 0;
      list-style: none;

      a {
        color: #a9a9a9;
      }

      li {
        flex: 1;
        margin-right: 3rem;
        white-space: nowrap;
        font-weight: 700;
        font-size: 1.825rem;
        line-height: 3.325rem;

        ul {
          display: block;
          margin-top: 2rem;
          margin-bottom: 2rem;

          li {
            font-size: 1.825rem;
            line-height: 3.325rem;
            font-weight: 400;
          }
        }
      }
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 2.25rem;
      margin-top: 1rem;
    }
  }
}
