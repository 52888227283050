.landing-for-professionals-view {
  .navbar {
    background-color: #175fd3;
    margin: 0 -0.75rem;
    padding-left: 1.75rem;

    img {
      margin-bottom: 0 !important;
    }
  }

  .section-1 {
    background-color: #fff;
    background-image: linear-gradient(#175fd3 50%, transparent 50%);
    margin: 0 -0.75rem;

    h1 {
      color: #fff;
      font-size: 4rem;
      line-height: 5rem;
      margin-bottom: 3rem;
      padding-right: 40%;

      span {
        background-color: #fff;
        color: #000;
        padding: 0 0.5rem;
      }
    }

    p {
      color: #fff;
      font-size: 1.75rem;
      position: relative;
      text-shadow: 0 0 5px #175fd3;
      z-index: 1;
    }

    button {
      background-color: #fff;
      color: #0e66ff;
      line-height: 1.5rem;
      padding: 1.5rem 3rem;
      border-radius: 10rem;
      font-size: 1.5rem;
      letter-spacing: 0.15rem;
      margin: 3rem 0;
      white-space: nowrap;
      border: 2px solid #175fd3;
    }

    img {
      margin-bottom: 4rem;
      margin-left: -8rem;
      width: 100%;
    }
  }

  .section-2 {
    background-color: #f4f8ff;
    margin: 0 -0.75rem;
    text-align: center;

    h1 {
      color: #000;
      margin-top: 0;
      font-size: 3.5rem;
      font-weight: 700;
      justify-content: center;
      padding: 5rem 1rem 3rem 1rem;
    }

    .row {
      margin: 0 auto;
    }

    p {
      font-size: 1.75rem;
      color: #7b7b7b;
      line-height: 2.5rem;
      margin: 0 auto;
      margin-bottom: 4rem;
    }

    img {
      width: 50%;
    }

    .partners {
      width: 90%;
      display: flex;
      margin: 4rem auto;
      justify-content: center;
      align-items: center;
      padding: 3rem;

      .col {
        flex: 1;
        text-align: center;

        img {
          padding: 2rem;
          width: 80%;
        }
      }
    }
  }

  .section-3 {
    text-align: center;
    color: #000;
    margin: 0 -0.75rem;

    h1 {
      font-size: 3rem;
      color: #000;
      padding-bottom: 20px;
    }

    h3 {
      font-size: 1.75rem;
      font-weight: 400;
      color: #7b7b7b;
      line-height: 40px;
    }

    img {
      margin-top: 4rem;
    }
  }

  .section-4 {
    margin: 0 -0.75rem;
    background-color: #f4f8ff;

    h1 {
      color: #000;
      text-align: center;
      font-size: 3rem;
      padding-top: 3rem;
    }

    .accordion-item {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #9fa19f;
      padding: 6px;

      .accordion-tile-name {
        font-size: 1.5rem;
      }

      & > div > .row {
        background-color: transparent;
      }
    }
  }

  .section-5 {
    background-color: #175fd3;
    color: #fff;
    text-align: center;
    margin: 0 -0.75rem;

    h1 {
      color: inherit;
      font-size: 3rem;
      font-weight: 500;
      padding-top: 4rem;
    }

    .footer-section {
      display: flex;
      justify-content: center;
      width: 100%;

      p {
        font-size: 1.75rem;
        line-height: 2.5rem;
        margin-bottom: 2.5rem;
      }

      button {
        background-color: #fff;
        border-radius: 10rem;
        color: #175fd3;
        font-size: 2rem;
        padding: 1.67rem 4rem;
        white-space: nowrap;
      }
    }
  }
}
