#product-card {
  padding: 0px;
  /* border: 0px; */
  border-radius: 0px;
  font-size: 1.25rem;
  margin: 0px 0px 0px -1px;
  cursor: pointer;

  .card-text {
    font-size: inherit;
  }
}

#product-card-image {
  /*border-radius: 5px 5px 0px 0px;*/
  border-radius: 0px;
}

#product-card-action-button {
  align-self: flex-end;
  font-size: 1.25rem;
  height: fit-content;
  margin: 0rem 0.25rem;
  width: 75%;
}

#credits-k {
  width: 16px;
  height: 16px;
  margin-right: 0.25rem;
}

#decorative-category-pills {
  border-radius: 12px;
  border: 1px;
  padding: 4px 12px;
  font-size: 1.1rem;
  text-align: center;
  font-weight: 500;
  max-width: fit-content;
}
#decorative-category-pills:disabled {
  opacity: 1;
}

#product-card-container {
  cursor: pointer;
  padding: 0px;
  border-radius: 0.25rem;
}

.selected-product-card {
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.25);
}

.btn.is-loading:after {
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  display: inline-block;
  overflow: hidden;
  position: absolute;
  vertical-align: bottom;
  width: 0;
}

@keyframes ellipsis {
  to {
    width: 1.1rem;
  }
}
