.verify-email-view {
  background-color: #fff;
  color: #000;
  margin-top: 4rem;
  text-align: center;

  img {
    margin-bottom: 4rem;
    max-width: 12rem;
  }

  h1 {
    color: inherit;
    font-weight: normal;
    margin-bottom: 4rem;
  }

  p {
    font-weight: 500;
  }

  button {
    border-radius: 3rem;
    font-size: 2rem;
    margin: 2rem auto;
    padding: 1.5rem 3rem;
  }
}
