.reset-view {
  background-color: #cad7eb;
  background-image: linear-gradient(
    107deg,
    #a0c1f5 0%,
    #cbd7ec 50%,
    #99bdf6 100%
  );
  min-height: calc(100vh - 6rem);

  a {
    color: #136ef1;
    text-decoration: none;
  }

  h1 {
    color: #000;
    font-size: 3.125rem;
  }

  input {
    background-color: #fff !important;
    border: none !important;
    border-radius: 0.4rem !important;
    color: #000;
    font-size: 1.625rem;
    margin: 0 !important;
  }

  button,
  button:hover {
    background-color: #459754;
    border-radius: 0.4rem !important;
    color: #fff;
    font-size: 1.625rem;
    font-weight: normal;
    padding: 1.22rem;
  }

  p {
    color: #000;
    font-size: 1.125rem;
  }

  .invalid-feedback {
    font-weight: bold;
  }
}
