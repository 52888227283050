$height: 3.325rem;

.vertically-scrolling-text {
  color: #f58d83;
  display: inline-block;
  height: $height;
  overflow: hidden;
  position: relative;

  font-style: normal;
  font-weight: 700;
  font-size: $height;
  line-height: $height;

  margin-bottom: -0.75rem;

  ul {
    padding: 0;
    margin: 0 0 0 0;
  }

  &.scrolling-up {
    ul {
      margin: -$height 0 0 0;
      transition: margin 500ms;
    }
  }
}
