#access-description-text {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}

#text-content {
  margin-left: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
}

#purchase-options-row {
  margin-bottom: 20px;
  margin-top: 12px;
}

#subscription-title-row {
  display: inline-flex;
}

#terms-and-conditions-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 16px;
}

#time-frame {
  font-weight: 400;
  font-size: 22px;
}

#subscription-title {
  font-size: 14px;
  font-weight: 700;
  margin-left: 5px;
}

#post-purchase-access-credentials {
  background-color: rgb(242, 203, 156);
  padding: 5px;
  margin-bottom: 10px;
}

.product-description {
  font-size: 14px;
  font-weight: 400;
}

#long-description-style {
  white-space: pre-line;
}

#name-text {
  font-size: 28px;
  font-weight: 700;
}

#price-text {
  font-size: 24px;
}

#filter-intro {
  color: #828282;
}

#screenshot-thumbnail {
  cursor: pointer;
  width: 72px;
  height: 45px;
}

#screenshot-display {
  width: 100%;
}

#thumbnail-div {
  margin-bottom: 10px;
}

#green-circle-access-icon {
  background: #219563;
  border: 4px solid #f2f2f2;
  border-radius: 19px;
  width: 16px;
  height: 16px;
  display: inline-block;
}
.primary-subscription {
  margin: 5px;
  padding: 5px;
  margin-left: 0px;
  border-radius: 4px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
}

.secondary-subscription {
  margin: 0px;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  background: #f2f2f2;
}

.key-pass-border {
  border: 2px solid #27ae60;
}

.subscription-pass-border {
  border: 2px solid #333333;
}

#form-check-box {
  border: 2px solid #1960d2;
  margin-right: 5px;
}

#access-price {
  float: right;
}

#access-expiration {
  color: #367cff;
  margin-left: auto;
  font-weight: bold;
  font-size: 14px;
}

#access-pwd {
  float: right;
}

#access-button {
  font-size: 1.5rem;
  padding: 1rem;
  width: 100%;
}

#join-waitlist-button {
  width: 100%;
  background: #3964b5;
  border-radius: 3px;
  margin-top: 10px;
}

#access-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #4f4f4f;
}

#buy-another-access {
  border: 1px black solid;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  font-style: italic;
}

#back-button {
  font-weight: 700;
}

#keye-pass {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  color: #1960d2;
  font-size: 14px;
}

#success-time-text {
  text-align: right;
  font-size: 12px;
  color: #1960d2;
  width: 100%;
  margin: 0px;
}

#success-title-text {
  font-size: 22px;
  color: #1960d2;
  font-weight: 400;
  margin-top: 0.5rem;
}

#success-login-details {
  font-size: 14px;
}

#success-product-text {
  font-weight: 500px;
  font-size: 28px;
  color: #000000;
}

#success-credentials-text {
  font-size: 12px;
  color: #000000;
}

#success-button-div {
  text-align: center;
  width: 100%;
  padding: 12px 0px;
}

#success-button-login {
  background-color: #219653;
  font-size: 1.25rem;
  width: 100%;
}

.waitlist-text {
  font-size: 14px;
}

.spam-waitlist-text {
  font-size: 14px;
  font-weight: 600;
}

#keye-pass-message-time {
  color: #3964b5;
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 0px;
}

#product-access-credits-k {
  height: 32px;
  width: 32px;
}

#about-product-accordion {
  .accordion-button {
    font-size: 1.55rem;
  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 768px;
  }
}
