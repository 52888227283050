.text-area-modal {
  width: 100%;
}

#whitelist-email {
  width: 100%;
  height: 110px;
  overflow: auto;
}

.credits-modal {
  /* width: 800px; */
  width: 90vw;
  max-width: 90vw;
}

.input-label {
  width: 100%;
}

.product-edit-input {
  width: 100%;
}
