#footer {
  padding: 5rem 0;
}

@media only screen and (max-width: 600px) {
  .flex-row {
    flex-direction: column !important;
  }
}

#footer-bottom {
  /* text-align: center;
  margin-top: 1rem; */
  /* position: fixed; */
  bottom: 0;
  left: 0px;
  right: 0px;
  background-color: #f8f8f8;
  /* box-shadow: 5px 10px; */
  border-top: 1px solid #e5e2e2;
}

.flex-direction-link {
  flex-direction: column;
}

.links-remove-boder {
  font-size: 1.15rem;
  outline: none;
  box-shadow: none;
}

.links-remove-boder:focus {
  outline: none;
  box-shadow: none;
}

.key-logo-footer {
  font-family: "Poppins", serif;
  color: #1960d2;
  text-transform: none;
  font-weight: 600;
}

#keye-logo-nav {
  margin-left: 0.5rem;
}

.text-middle {
  font-size: 1.1rem;
  margin: 0px;
  margin-right: 10px;
  font-weight: 600;
}

.margin10 {
  margin: 10px;
}

.link-color {
  color: #4f7ddd;
}

.button-color {
  background-color: #4f7ddd;
  color: #f8f8f8;
}

#feedback-button {
  font-size: 1.15rem;
  width: fit-content;
}

@media (min-width: 320px) {
  .key-logo-footer {
    margin-bottom: 10px;
  }
  .footer-social-media {
    display: none !important;
  }

  .footer-links {
    display: none !important;
  }
}

@media (min-width: 481px) {
  .key-logo-footer {
    margin-bottom: 10px;
  }
  .footer-social-media {
    display: none !important;
  }

  .footer-links {
    display: none !important;
  }
}

@media (min-width: 641px) {
  .key-logo-footer {
    margin-bottom: 0px;
  }
  .footer-social-media {
    display: none !important;
  }

  .footer-links {
    display: none !important;
  }
}

@media (min-width: 961px) {
  .key-logo-footer {
    margin-bottom: 0px;
  }
  .footer-social-media {
    display: initial !important;
  }

  .footer-links {
    display: initial !important;
  }
}
