.landing-for-independent-pros-view {
  .navbar {
    padding-left: 1.75rem;

    img {
      margin-bottom: 0 !important;
    }
  }

  .navbar,
  .section-1 {
    background-color: #fff;
    background-image: linear-gradient(to right, #f4f8ff 30%, transparent 30%);
    margin: 0 -0.75rem;
  }

  .section-1 {
    h1 {
      color: #000;
      font-size: 4rem;
      line-height: 4rem;
      margin-bottom: 3rem;
    }

    p {
      font-size: 1.75rem;
    }

    button {
      line-height: 1.5rem;
      padding: 1.5rem 3rem;
      border-radius: 10rem;
      font-size: 1.5rem;
      margin: 3rem 0;
      white-space: nowrap;
      letter-spacing: 0.15rem;
    }

    img {
      margin-bottom: 4rem;
      margin-left: -6rem;
      marign-top: -4rem;
      width: 100%;
    }
  }

  .section-1-5 {
    .partners {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem 1rem 3rem 1rem;
      margin: 0 auto;

      .col {
        flex: 1;
        text-align: center;

        img {
          padding: 2rem;
          width: 80%;
        }
      }
    }
  }

  .section-2 {
    margin: 0 -0.75rem;

    h1 {
      color: #000;
      margin-top: 0;
      font-size: 3.5rem;
      font-weight: 700;
      justify-content: center;
      padding: 5rem 0 3rem 0;
    }

    .row {
      margin: 0 auto;
    }

    img {
      width: 100%;
    }

    p {
      font-size: 1.75rem;
      color: #7b7b7b;
      line-height: 2.5rem;
      margin-bottom: 0;
      padding-right: 3rem;
    }
  }

  .section-3 {
    text-align: center;
    color: #000;
    margin: 0 -0.75rem;

    h1 {
      font-size: 3rem;
      color: #000;
      padding-bottom: 20px;
    }

    h3 {
      font-size: 1.75rem;
      font-weight: 400;
      color: #7b7b7b;
      line-height: 40px;
    }

    img {
      margin-top: 4rem;
      max-width: 75%;
    }
  }

  .section-4 {
    margin: 0 -0.75rem;

    h1 {
      color: #000;
      text-align: center;
      font-size: 3rem;
      padding-top: 3rem;
    }

    .accordion-item {
      border: none;
      border-bottom: 1px solid #9fa19f;
      padding: 6px;

      .accordion-tile-name {
        font-size: 1.5rem;
      }

      .accordion-content {
        font-size: 1.25rem;
        line-height: 165%;
      }
    }
  }

  .section-5 {
    background-color: #175fd3;
    color: #fff;
    text-align: center;
    margin: 0 -0.75rem;

    h1 {
      color: inherit;
      font-size: 3rem;
      font-weight: 500;
      padding-top: 4rem;
    }

    .footer-section {
      display: flex;
      justify-content: center;
      width: 100%;

      p {
        font-size: 1.75rem;
        line-height: 2.5rem;
        margin-bottom: 2.5rem;
      }

      button {
        background-color: #fff;
        border-radius: 10rem;
        color: #175fd3;
        font-size: 2rem;
        padding: 1.67rem 4rem;
        white-space: nowrap;
      }
    }
  }
}
