.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.opacity-100 {
  opacity: 1;
}
.mx-auto {
  padding: 0px;
}
.w-full {
  width: 100%;
}
.z-30 {
  z-index: 30;
}
.fixed {
  position: fixed;
}
.bg-body,
.bg-white {
  background-color: #fff !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}

.align-middle {
  vertical-align: middle;
}
.pb-5 {
  padding-bottom: 32px;
}
.pt-5 {
  padding-top: 32px;
}
.justify-between {
  justify-content: space-between;
}
.items-stretch {
  align-items: stretch;
}

.flex {
  display: flex;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.relative {
  position: relative;
}

.container {
  width: 100%;
}
.pb-5 {
  padding-bottom: 3rem !important;
}
.pt-5 {
  padding-top: 3rem !important;
}
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.align-middle {
  vertical-align: middle !important;
}
.my-auto {
  margin-bottom: auto !important;
  margin-top: auto !important;
}
.font-bold {
  font-weight: 700;
  font-size: 60px;
}
.my-6 {
  margin-bottom: 42px;
  margin-top: 42px;
}

.w-12 {
  width: 360px;
}
.z-10 {
  z-index: 10;
}
.relative {
  position: relative;
}

img {
  height: auto;
  max-width: 100%;
}

.bg-primary-half {
  background-image: linear-gradient(180deg, #e5efff 50%, #fff 0);
}
.pt-9 {
  padding-top: 120px;
}
.pb-9 {
  padding-bottom: 120px;
}

.flex-col {
  flex-direction: column;
}
.pb-6 {
  padding-bottom: 42px;
}
.pt-6 {
  padding-top: 42px;
}
.justify-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}
.max-w-screen-1920 {
  max-width: 1920px;
}
.flex {
  display: flex;
}
.flex {
  display: flex;
}
.mt-6 {
  margin-top: 42px;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-2xl {
  border-radius: 1rem;
}
.bg-white {
  background-color: #fff !important;
}

.shadow-button,
.shadow-none {
  box-shadow: 20px 20px 10px rgba(196, 196, 196, 0.33);
}

.text-mini {
  font-size: 16px;
}

.pr-2 {
  padding-right: 12px;
}

.text-superMini {
  font-size: 14px;
}
.rounded-full {
  border-radius: 9999px;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.text-white {
  color: #fff !important;
}
.py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}
button {
  border: none;
  padding: 0;
  margin: 0;
}
.w-11 {
  width: 300px;
}
.bg-primaryOrange {
  background-color: orangered;
  width: 200px;
}

.bg-blue-2 {
  background-color: rgb(246, 249, 255);
}
.pb-9 {
  padding-bottom: 120px;
}
.pt-9 {
  padding-top: 120px;
}
.items-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.items-center {
  align-items: center;
}
.flex-col {
  flex-direction: column;
}
