#nav-keye-header {
  font-family: "Poppins", serif;
  font-size: 2rem;
  color: #1960d2;
  text-transform: none;
}

#keye-logo-nav {
  margin-left: 0.5rem;
}
.refer-image {
  height: auto;
  text-align: end;
}

.nav-link {
  font-size: 1.5rem;
  text-transform: none;
}

#sign-out-text {
  cursor: pointer;
  font-size: 1.25rem;
  text-transform: none;

  .logout-button {
    color: #828282;
    margin-left: 0.5rem;
  }
}

.name-or-email {
  font-size: 1.25rem;
}

input,
select {
  box-shadow: none !important;
  /* color: #FD8067 !important;
  border: 0.5px solid #FD8067 !important; */
  border: 1px solid #858688 !important;
  border-radius: 10px !important;
  background-color: unset !important;
  margin-bottom: 15px !important;
}
.btn-bg-color {
  background-color: #fd8067;
}
.btn-bg-color:hover {
  background-color: #fd8067;
}

.refer-header-text {
  color: #fd8067;
}
.refer-sub-text {
  color: #818181;
  font-size: "600, Semi Bold";
  font-weight: 500;
}
.refer-header-sub-text {
  color: #fd8067;
  font-size: "600, Semi Bold";
  padding-top: 12%;
}

.model-popup {
  width: 100vw;
}
.refer-container {
  background-color: #ffeae6;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
  margin: 10px 0px;
  padding: 10px 20px;
}
.refer-success {
  background-color: #fd8067;
  color: #fff;
  padding-top: 12px;
}
.reset-mp {
  margin: 0px;
  padding: 0px;
}

/* smartphones, iPhone, portrait 480x320 phones */
@media (min-width: 320px) {
  .reset-mp {
    margin: 10px;
    padding: 0px;
  }
  .refer-image {
    display: initial;
  }
}
/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@media (min-width: 481px) {
  .reset-mp {
    margin: 10px;
    padding: 0px;
  }
  .refer-image {
    display: initial;
  }
}
/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
@media (min-width: 641px) {
  .reset-mp {
    margin: 0px;
    padding: 0px;
  }
  .refer-image {
    display: none;
  }
}
/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width: 961px) {
  .reset-mp {
    margin: 0px;
    padding: 0px;
  }
  .refer-image {
    display: initial;
  }
}

.btn-bg-suceess-color,
.btn-bg-suceess-color:hover {
  background-color: #0069f5;
  color: #fff;
  width: 300px !important;
}
.refer-success-code {
  color: #000;
}
.format-credit-message {
  margin: 10px 10px;
}
.add-extn,
.add-extn:hover {
  color: #fff;
  background-color: #2e7cf6;
  font-size: 18px;
  line-height: 22px;
  margin-right: 20px;
}
