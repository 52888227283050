#request-product-header {
  background-color: black;
  color: white;
  font-weight: 700;
  font-size: large;
  padding: 1rem;
  width: 100%;
}

#product-request-form {
  border: 1px solid black;
  margin: 1rem 0rem;
}
#request-product-form-body {
  padding: 0.5rem 1.5rem;
}
