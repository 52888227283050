.chrome-ext {
  background-color: #1960d2;
  display: flex;
  justify-content: space-around;
}
.chrome-ext-text {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
