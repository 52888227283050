.m-3.toggle-button.btn {
  margin-top: 5px !important;
  margin-left: 0px !important;
}

.m-3.toggle-button.btn:hover {
  background-color: #367cff !important;
}

.form-check-input:checked {
  background-color: #367cff !important;
}
.form-check-input:focus {
  box-shadow: none;
}

#required-text {
  color: red;
}
