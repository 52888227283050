.landing-view-old {
  .navbar {
    margin: 0 -0.75rem;
    padding-left: 1.75rem;

    img {
      margin-bottom: 0 !important;
    }
  }

  .section-1 {
    background: transparent url(../../public/images/landing-background.svg) top
      right no-repeat;
    background-size: contain;
    line-height: 36px;
    padding: 3rem 2rem;
    margin: 0 -0.75rem;

    h1 {
      font-size: 4rem;
      line-height: 4rem;
      color: #000;
    }

    p {
      font-size: 1.75rem;
    }

    button {
      line-height: 1.5rem;
      padding: 1.5rem 3rem;
      border-radius: 10rem;
      font-size: 1.5rem;
      margin: 3rem 0;
      white-space: nowrap;
      letter-spacing: 0.15rem;
    }

    ul.list {
      list-style-image: url("data:image/svg+xml,%3Csvg width='26' height='18' viewBox='0 0 26 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.3441 1.82125L7.96528 17.3212L0 10.2171L2.04201 8.39583L7.96528 13.6658L23.3021 0L25.3441 1.82125Z' fill='%230E66FF'/%3E%3C/svg%3E%0A");

      li {
        font-style: normal;
        font-size: 1.75rem;
        line-height: 2rem;
        margin-bottom: 1rem;
        padding-left: 0.5rem;
      }
    }

    img {
      width: 100%;
    }
  }

  .section-2 {
    background-color: #fff;
    margin: 0 -0.75rem;
    background-image: linear-gradient(#175fd3 30rem, transparent 30rem);

    h1 {
      color: #fff;
      margin-top: 0;
      font-size: 3.5rem;
      font-weight: 700;
      justify-content: center;
      padding: 5rem 1rem 3rem 1rem;
      text-align: center;
    }

    .box {
      background-color: #fff;
      display: inline-block;

      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      width: 70%;
      background-color: #fff;
      text-align: center;
      vertical-align: middle;
      color: #000;
      margin-left: 15%;
      font-size: 30px;
      font-weight: 700;
      justify-content: center;
      padding: 2rem;

      img {
        margin: 0 auto;
      }

      p {
        margin-top: 4rem;
        font-size: 24px;
        margin-left: 7%;
        margin-right: 7%;
        color: #7b7b7b;
      }
    }

    .partners {
      width: 90%;
      display: flex;
      margin: 4rem auto;
      justify-content: center;
      align-items: center;
      padding: 1rem;

      .col {
        flex: 1;
        text-align: center;

        img {
          padding: 2rem;
          width: 80%;
        }
      }
    }
  }

  .section-3 {
    background-color: #f4f8ff;
    text-align: center;
    position: relative;
    color: #000;
    margin: 0 -0.75rem;

    h1 {
      font-size: 3rem;
      color: #000;
      padding-bottom: 20px;
    }

    h3 {
      font-size: 1.75rem;
      font-weight: 400;
      color: #7b7b7b;
      line-height: 40px;
    }

    img {
      margin-top: 5rem;
      max-width: 75%;
    }
  }

  .section-4 {
    margin: 0 -0.75rem;

    h1 {
      color: #000;
      text-align: center;
      font-size: 3rem;
    }

    h2 {
      color: #000;
      font-weight: normal;
      font-size: 3rem;
      margin-top: 2rem;
      text-align: center;
    }

    .accordion-item {
      border: none;
      border-bottom: 1px solid #9fa19f;
      padding: 6px;

      .accordion-tile-name {
        font-size: 1.5rem;
      }

      .accordion-content {
        font-size: 1.25rem;
        line-height: 165%;
      }
    }
  }

  .section-5 {
    background-color: #175fd3;
    color: #fff;
    text-align: center;
    margin: 0 -0.75rem;

    h1 {
      color: inherit;
      font-size: 3rem;
      font-weight: 500;
      padding-top: 4rem;
    }

    .footer-section {
      display: flex;
      justify-content: center;
      width: 100%;

      p {
        font-size: 1.75rem;
        line-height: 2.5rem;
        margin-bottom: 2.5rem;
      }

      button {
        background-color: #fff;
        border-radius: 10rem;
        color: #175fd3;
        font-size: 2rem;
        padding: 1.67rem 4rem;
        white-space: nowrap;
      }
    }
  }
}
